.card.bg-image {
  overflow: hidden;
}

.card-body.bg-terproteksi {
  position: relative;
  z-index: 10;
  // background: #333;
  background: rgb(132 90 223);
  opacity: 1;
}

.bg-terproteksi::before {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  // background-image: url('https://img.freepik.com/free-vector/hand-drawn-flat-mountain-landscape_23-2149174187.jpg?t=st=1713942998~exp=1713946598~hmac=f20ed4572f9896713913c1dae1409a77cb3aea891395b456cdbcdc5dd2ac3ee4&w=1380');
  background-image: url('https://laravelui.spruko.com/tailwind/ynex/build/assets/images/media/media-65.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  content: '';
  opacity: .2;
}

.card-content {
  position: relative;
  z-index: 20;
  color: white !important;
}

h4.header-title {
  padding-left: 10px;
}

h4.header-title::before {
  position: absolute;
  left: 20px;
  content: '';
  // background-color: #d35497 ;
  background: linear-gradient(180deg, #dfc5d3 0%, #d4599a 100%);
  height: 16px;
  width: 3px;
  // opacity: .8;
  border-radius: .5rem;
}

.list-group-item:hover {
  border-radius: .4rem !important;
}